import { AppState } from '~/providers'
import { buildClientInfo } from '~/lib/client-info'
import type { IClientInfo } from '~/types/client-info'

export const useClientInfo = () => {
  const { ssrContext } = useNuxtApp()

  return useState<IClientInfo>(AppState.ClientInfo, () =>
    buildClientInfo({
      request: ssrContext?.event.node.req,
      headers: ssrContext?.event.node.req.headers as any as Record<string, string | string[]>
    })
  ).value
}
