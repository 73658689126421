/**
 * All the global actions that need to be forwarded to Partytown.
 */
export const partytownForwardDatadogRum = [
  'DD_RUM.version',
  'DD_RUM.onReady',
  'DD_RUM.init',
  'DD_RUM.addRumGlobalContext',
  'DD_RUM.setGlobalContextProperty',
  'DD_RUM.removeRumGlobalContext',
  'DD_RUM.removeGlobalContextProperty',
  'DD_RUM.getRumGlobalContext',
  'DD_RUM.getGlobalContext',
  'DD_RUM.setRumGlobalContext',
  'DD_RUM.setGlobalContext',
  'DD_RUM.clearGlobalContext',
  'DD_RUM.getInternalContext',
  'DD_RUM.getInitConfiguration',
  'DD_RUM.addAction',
  'DD_RUM.addError',
  'DD_RUM.addTiming',
  'DD_RUM.setUser',
  'DD_RUM.getUser',
  'DD_RUM.setUserProperty',
  'DD_RUM.removeUserProperty',
  'DD_RUM.removeUser',
  'DD_RUM.clearUser',
  'DD_RUM.startView',
  'DD_RUM.stopSession',
  'DD_RUM.startSessionReplayRecording',
  'DD_RUM.stopSessionReplayRecording',
  'DD_RUM.addFeatureFlagEvaluation',
  'DD_RUM.getSessionReplayLink'
]

const datadogRum = (h, o, u, n, d) => {
  h = h[d] = h[d] || {
    q: [],
    onReady: function (c) {
      h.q.push(c)
    }
  }
  d = o.createElement(u)
  d.async = 1
  d.src = n
  n = o.getElementsByTagName('head')[0]
  n.insertAdjacentElement('beforeend', d)
}

export const injectDatadogRumScript = ({ $config, $experiment }) => {
  if (!$config.enabled) return

  const datadogConfig = { ...$config }

  if ($experiment.datadogRumTrackInteraction != null)
    datadogConfig.trackUserInteractions = $experiment.datadogRumTrackInteraction.value
  if ($experiment.datadogRumSampleRate != null) datadogConfig.sessionSampleRate = $experiment.datadogRumSampleRate.value

  return `(${datadogRum
    .toString()
    .replace(/^\s+$/gm, '')
    .replace(
      /\n\n+/g,
      '\n\n'
    )})(window,document,'script','https://www.datadoghq-browser-agent.com/us1/v4/datadog-rum.js','DD_RUM')
      window.DD_RUM.onReady(function() {
        window.DD_RUM.init(${JSON.stringify(datadogConfig).replace(/"[*]regex(\/[^/]+?\/[gims]*)"/g, '$1')});
      })`
}
