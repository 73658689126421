<template>
  <div class="bg-white">
    <slot />
  </div>
</template>

<script lang="ts" setup>
import { injectDatadogRumScript } from '~/lib/partytown/datadog-rum'
import { injectSegmentScript } from '~/lib/partytown/segment'
import { injectReddit } from '~/lib/scripts/reddit-pixel'
import { AppState } from '~/providers'

const $config = useRuntimeConfig()
const experiments = useExperiment()

useClientInfo()

const { ssrContext } = useNuxtApp()
const isBot = /bot/.test(ssrContext?.event.node.req.headers['user-agent'] ?? '')

useState(AppState.IsBot, () => isBot)

if (experiments?.maintenanceMode.value) {
  throw createError({ statusCode: 503, statusMessage: 'Maintenance Mode' })
}

useHead(
  {
    script: [
      {
        key: 'segment',
        type: $config.public.segment.usePartytown ? 'text/partytown' : 'text/javascript',
        innerHTML: injectSegmentScript($config.public.segment)
      },
      {
        key: 'datadog-rum',
        type: $config.public.segment.usePartytown ? 'text/partytown' : 'text/javascript',
        innerHTML: injectDatadogRumScript({ $config: $config.public.datadogRum, $experiment: experiments ?? {} })
      },
      {
        key: 'reddit',
        type: 'text/javascript',
        innerHTML: injectReddit({ $config: $config.public.reddit.pixel })
      }
    ]
  },
  {
    mode: 'server'
  }
)
</script>
