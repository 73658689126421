export const forwardReddit = ['rdt']
/**
 * Injects the Reddit Pixel script for Partytown into the head.
 *
 * __dangerouslyDisableSanitizersByTagID is required to tell Nuxt to not escape
 * characters in this block.
 */
export const injectReddit = ({ $config }: { $config: any }) => {
  if (!$config.enabled || !$config.id) {
    return
  }

  return injectRedditPixelScript({ pixelId: $config.id })
}

export function injectRedditPixelScript({ pixelId }: { pixelId: string }) {
  // Modified to be injected at the end of the head tag
  return `!function(w,d){if(!w.rdt){var p=w.rdt=function(){p.sendEvent?p.sendEvent.apply(p,arguments):p.callQueue.push(arguments)};p.callQueue=[];var t=d.createElement("script");t.src="https://www.redditstatic.com/ads/pixel.js",t.async=!0;var s=d.getElementsByTagName("script")[0];s.parentNode.insertBefore(t,s)}}(window,document);rdt('init','${pixelId}');rdt('track', 'PageVisit');`
}
