export const partytownForwardSegment = [
  'analytics.track',
  'analytics.identify',
  'analytics.group',
  'analytics.reset',
  'analytics.user',
  'analytics.alias',
  'analytics.page',
  'analytics.setAnonymousId'
]

export const injectSegmentScript = ($config) => {
  if (!$config.enabled) return

  return `
    (function(){
    // Create a queue, but don't obliterate an existing one!
    var analytics = window.analytics = window.analytics || [];
    window.analytics_referrer_count = 0

    // If the real analytics.js is already on the page return.
    if (analytics.initialize) return;

    // If the snippet was invoked already show an error.
    if (analytics.invoked) {
      if (window.console && console.error) {
        console.error('Segment snippet included twice.');
      }
      return;
    }

    // Invoked flag, to make sure the snippet
    // is never invoked twice.
    analytics.invoked = true;

    // A list of the methods in Analytics.js to stub.
    analytics.methods = [
      'trackSubmit',
      'trackClick',
      'trackLink',
      'trackForm',
      'pageview',
      'identify',
      'reset',
      'group',
      'track',
      'ready',
      'alias',
      'debug',
      'page',
      'once',
      'off',
      'on',
      'addSourceMiddleware',
      'addIntegrationMiddleware',
      'setAnonymousId',
      'addDestinationMiddleware'
    ];

    // Define a factory to create stubs. These are placeholders
    // for methods in Analytics.js so that you never have to wait
    // for it to load to actually record data. The "method" is
    // stored as the first argument, so we can replay the data.
    analytics.factory = function(method){
      return function(){
        var args = Array.prototype.slice.call(arguments);
        args.unshift(method);
        analytics.push(args);
        return analytics;
      };
    };

    // For each of our methods, generate a queueing stub.
    for (var i = 0; i < analytics.methods.length; i++) {
      var key = analytics.methods[i];
      analytics[key] = analytics.factory(key);
    }

    // Define a method to load Analytics.js from our CDN,
    // and that will be sure to only ever load it once.
    analytics.load = function(key, options){
      // Create an async script element based on your key.
      var script = document.createElement('script');
      script.type = 'text/javascript';
      script.defer = true;
      script.src = ${JSON.stringify(
        ($config.url || 'https://cdn.segment.com') + '/analytics.js/v1/'
      )} + key + '/analytics.min.js';

      // Insert our script end to the head element.
      var head = document.getElementsByTagName('head')[0];
      head.insertAdjacentElement('beforeend', script);

      analytics._loadOptions = options;
    };

    // Collect missing context fields (ANALYTICS.JS)
    analytics.addSourceMiddleware(({ payload, next }) => {
      payload.obj.context.screen = {
        height: window.innerHeight,
        width: window.innerWidth,
        density: window.devicePixelRatio || 1
      }
      payload.obj.context.host = window.location.host
      if (window.analytics_referrer_count++ > 0) payload.obj.context.page.referrer = ''

      if (payload.obj.userId) payload.obj.userId = 'mp_' + payload.obj.userId
      if (payload.obj.event) payload.obj.event = 'Mp' + payload.obj.event
      next(payload)
    })

    analytics._writeKey = ${JSON.stringify($config.writeKey)}

    // Add a version to keep track of what's in the wild.
    // analytics.SNIPPET_VERSION = '4.15.2';

    // Load Analytics.js with your key, which will automatically
    // load the tools you've enabled for your account. Boosh!
    analytics.load(${JSON.stringify($config.writeKey)}, {
      integrations: { "Segment.io": { apiHost: ${JSON.stringify($config.apiHost || 'analytics-api.rvezy.com/v1')} } },
      user: {
        persist: true,
        cookie: {
          key: 'ajs_mp_user_id'
        }
      }
    });

    if (${JSON.stringify($config.enablePageViewTracking)}) {
      analytics.page()
    }
  })();
  `
}
