import type { IncomingMessage } from 'http'
import type { IncomingHttpHeaders } from 'http2'
import type { IClientInfo } from '~/types/client-info'

export const buildClientInfo = ({
  headers,
  request
}: {
  headers?: IncomingHttpHeaders
  request?: IncomingMessage
}): IClientInfo => {
  return {
    userAgent: headers?.['user-agent']!,
    requestUri: request?.url
  }
}
